import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['element', 'form']
  static values = { message: String }

  connect = () => {
    this.formIsDirty = false
    this.formSubmitting = false
    this.switchElements = []

    this.elementTargets.forEach((element) => {
      element.querySelectorAll('input, textarea, #editor').forEach((el) => {
        el.addEventListener('input', (_event) => {
          this.formIsDirty = true
        })
        el.addEventListener('change', (_event) => {
          this.formIsDirty = true
        })
      })

      const switchElement = element.querySelector('[data-controller="switch"]')
      if (switchElement) this.switchElements.push(switchElement)
    })

    if (!!this.switchElements.length) {
      window.addEventListener('checkboxChanged', this.boundHandleCheckboxChanged)
    }

    // Warn if leaving page
    document.addEventListener('turbo:before-visit', this.boundUnsavedChangesEvent)
  }

  handleCheckboxChanged = ({ detail: { switchElement } }) => {
    if (this.switchElements.includes(switchElement)) this.formIsDirty = true
  }

  boundHandleCheckboxChanged = this.handleCheckboxChanged.bind(this)

  elementRemoved = ({ detail: { element } }) => {
    if (
      this.elementTargets.includes(element) ||
      this.elementTargets.some((elementTarget) => elementTarget.contains(element))
    ) {
      this.formIsDirty = true
    }
  }

  unsavedChangesEvent = (event) => {
    if (this.formIsDirty && !this.formSubmitting) {
      let confirmation = confirm(this.message())

      if (!confirmation) event.preventDefault()
    }
  }

  boundUnsavedChangesEvent = this.unsavedChangesEvent.bind(this)

  submit = (_event) => {
    this.formSubmitting = true
  }

  message = () => {
    return (
      this.messageValue ||
      'You have unsaved changes. Update the form before leaving to save them.'
    )
  }

  disconnect = () => {
    document.removeEventListener('turbo:before-visit', this.boundUnsavedChangesEvent)
    window.removeEventListener('checkboxChanged', this.boundHandleCheckboxChanged)
  }
}
