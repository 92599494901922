import { Controller } from "stimulus"
import { clearHover } from "src/utils"

export default class extends Controller {
  static targets = ['icon', 'preview', 'field', 'name']

  uploadFile() {
    const droppable = this.fieldTarget.closest(".droppable")
    clearHover(droppable)

    const file = this.fieldTarget.files[0]

    if (file === undefined || file.type !== 'application/zip') return

    this.iconTarget.classList.add('hide')
    this.previewTarget.classList.remove('hide')

    this.nameTarget.innerHTML = file.name
  }
}
