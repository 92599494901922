import { addSelectionId, removeSelectionId } from 'src/utils'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['ids']

  toggle(event) {
    let tag = event.target
    let id = tag.dataset.categoryId

    if (Array.from(tag.classList).includes('selected')) {
      tag.classList.remove('selected')
      removeSelectionId(id, '#video_category_ids')
    } else {
      tag.classList.add('selected')
      addSelectionId(id, '#video_category_ids')
    }
  }
}
