import { DirectUpload } from "@rails/activestorage";

export class DirectFileUpload {
  constructor(file, url, index, attachmentType) {
    this.directUpload = this.createDirectUpload(file, url, this)
    this.url = url
    this.file = file
    this.index = index
    this.attachmentType = attachmentType
  }

  createDirectUpload(file, url, class_instance) {
    return new DirectUpload(file, url, class_instance)
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", (event) => {
      this.progressUpdate(event)
    })
  }

  progressUpdate(event) {
    const progress = (event.loaded / event.total) * 100;
    this.emitProgressEvent(progress);
  }

  emitProgressEvent(progress) {
    const event = new CustomEvent('directUploadProgress', {
      detail: {
        progress,
        index: this.index,
        fileName: this.file.name,
        fileSize: this.file.size,
        attachmentType: this.attachmentType
      }
    });
    document.dispatchEvent(event);
  }
}
