import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['textField']

  clearInput(event){
    event.preventDefault();

    this.textFieldTarget.value = '';
  }
}