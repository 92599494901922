import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'button']

  update(event) {
    event.preventDefault()
    this.inputTarget.value = this.buttonTarget.dataset.button
    this.inputTarget.form.requestSubmit()
  }
} 
