import { Controller } from 'stimulus'
import { Chart, DoughnutController, ArcElement } from 'chart.js'

export default class extends Controller {
  static targets = ['ctx', 'label']
  static values = { data: Object, backgroundColors: Array }

  connect() {
    if (!this.hasCtxTarget) return

    // Removes element if Turbo Drive is displaying a preview to avoid loading the chart twice
    if (document.documentElement.hasAttribute('data-turbo-preview')) {
      this.element.remove()
      return
    }

    Chart.register(DoughnutController, ArcElement)
    this.setChart()
  }

  setChart = () => new Chart(this.ctxTarget, this.getChartOptions())

  getChartOptions = () => ({
    type: 'doughnut',
    data: {
      labels: this.getLabels(),
      datasets: [
        {
          data: this.getData(),
          backgroundColor: this.getBackgroundColors(),
        },
      ],
    },
    options: {
      cutout: '70%',
      onResize: this.positionLabelInside,
    },
    plugins: [this.EmptyChart()],
  })

  getLabels = () => Object.keys(this.dataValue)

  getData = () => Object.values(this.dataValue)

  getBackgroundColors() {
    if (this.hasBackgroundColorsValue) return this.backgroundColorsValue
  }

  EmptyChart = () => ({
    id: 'emptyChart',
    beforeInit: (chart) => {
      const dataset = chart.data.datasets[0]
      const data = dataset.data
      const isAllZero = data.reduce((a, b) => a + b) > 0 ? false : true
      if (!isAllZero) return
      // when all data values are zero...
      dataset.data = data.map((e, i) => (i > 0 ? 0 : 1)) //add one segment
      dataset.backgroundColor = '#c7ccd7' //change bg color
      dataset.borderWidth = 0 //no border
    },
  })

  /**
   * Position a legend inside the Doughnut chart
   * @param {Chart} chart - The Chart instance
   * @returns {void}
   */
  positionLabelInside = (chart) => {
    if (!this.hasLabelTarget) return

    const xPos = chart.width / 2 - this.labelTarget.offsetWidth / 2
    const yPos = chart.height / 2 - this.labelTarget.offsetHeight / 2

    this.labelTarget.style.left = `${xPos}px`
    this.labelTarget.style.bottom = `${yPos}px`
    this.labelTarget.classList.add('chart-label--visible')
  }
}
