import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    this.showAuthForm();
  }

  showAuthForm() {
    let element = document.getElementById("clearance");
    if(element) {
      element.classList.add("show");
    }
  }

}
