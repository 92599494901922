import { Controller } from "stimulus";

const BTN_DROPDOWN_MORPH_ITEM = "btn-dropdown-morph__item";
const BTN_DROPDOWN_MORPH_EXPANDED = "btn-dropdown-morph--expanded";
const KEY_DOWN = 40;
const KEY_UP = 38;
const OPEN_BG_CLASS = "open-bg";

export default class extends Controller {
  static targets = ["listWrapper", "item", "btn", "morphBg"];

  connect() {
    this.initSplitBtn();
    this.initSplitBtnEvents();
  }

  initSplitBtn() {
    this.setBtnAttributes();
    this.setItemAttributes();
  }

  setBtnAttributes() {
    this.btnTargets.forEach((btn) => {
      btn.setAttribute("aria-expanded", "false");
      btn.setAttribute("aria-haspopup", "true");
    });
  }

  setItemAttributes() {
    this.itemTargets.forEach((item) => {
      item.setAttribute("tabindex", "0");
    });
  }

  initSplitBtnEvents() {
    this.addClickEventListener();
    this.addKeyDownEventListener();
    this.addBodyClickEventListener();
  }

  addClickEventListener() {
    this.btnTargets[0].addEventListener("click", this.handleBtnClick.bind(this));
  }

  handleBtnClick(event) {
    event.preventDefault();
    const isOpen = !this.element.classList.contains(BTN_DROPDOWN_MORPH_EXPANDED);
    this.toggleMenu(isOpen, true);
  }

  addKeyDownEventListener() {
    this.element.addEventListener("keydown", this.handleKeyDown.bind(this));
  }

  handleKeyDown(event) {
    if (event.target.classList.contains(BTN_DROPDOWN_MORPH_ITEM)) {
      this.handleKeyDownEvent(event);
    }
  }

  handleKeyDownEvent(event) {
    if (event.keyCode === KEY_DOWN || event.key.toLowerCase() === "arrowdown") {
      this.navigateItems(event, "next");
    } else if (event.keyCode === KEY_UP || event.key.toLowerCase() === "arrowup") {
      this.navigateItems(event, "prev");
    }
  }

  addBodyClickEventListener() {
    document.body.addEventListener("click", this.handleBodyClick.bind(this));
  }

  handleBodyClick(event) {
    this.checkMenuClick(event.target);
  }

  toggleMenu(isOpen, focusItem) {
    this.element.classList.toggle(BTN_DROPDOWN_MORPH_EXPANDED, isOpen);
    this.menuIsOpen = isOpen;
    this.animateBg(isOpen);

    this.updateBtnAttributes(isOpen, focusItem);
    this.updateItemAttributes(isOpen);
  }

  updateBtnAttributes(isOpen, focusItem) {
    if (!this.btnTargets[0]) return;
    const expandedState = isOpen ? "true" : "false";
    this.btnTargets[0].setAttribute("aria-expanded", expandedState);
    if (isOpen || focusItem) {
      this.btnTargets[0].focus();
    }
  }

  updateItemAttributes(isOpen) {
    if (!isOpen) return;
    this.itemTargets[0].focus();
    this.itemTargets[0].addEventListener("transitionend", () => this.itemTargets[0].focus(), { once: true });
  }

  navigateItems(event, direction) {
    event.preventDefault();

    const currentIndex = Array.prototype.indexOf.call(this.itemTargets, event.target);
    let nextIndex = direction === "next" ? currentIndex + 1 : currentIndex - 1;

    if (nextIndex < 0) {
      nextIndex = this.itemTargets.length - 1;
    } else if (nextIndex > this.itemTargets.length - 1) {
      nextIndex = 0;
    }

    this.itemTargets[nextIndex].focus();
  }

  checkMenuFocus() {
    const focusedElement = document.activeElement.closest(".btn-dropdown-morph");
    if (!focusedElement || !this.element.contains(focusedElement)) {
      this.toggleMenu(false, false);
    }
  }

  checkMenuClick(target) {
    const isClickInsideMenu = this.element.contains(target);
    const isClickOnControlElement = target.closest(`[aria-controls="${this.elementId}"]`);
    if (!isClickInsideMenu && !isClickOnControlElement) {
      this.toggleMenu(false);
    }
  }

  animateBg(isOpen) {
    if (isOpen) {
      const listRect = this.listWrapperTargets[0].getBoundingClientRect();
      const bgRect = this.morphBgTargets[0].getBoundingClientRect();

      this.morphBgTargets[0].style.transform = `translateX(${listRect.left - bgRect.left}px) translateY(${listRect.top - bgRect.top}px) translateZ(-0.1px)`;
      this.morphBgTargets[0].style.height = `${listRect.height}px`;
      this.morphBgTargets[0].style.width = `${listRect.width}px`;
    } else {
      this.morphBgTargets[0].style = "";
    }
  }

}
