import { Controller } from "stimulus";
import { DirectUpload } from "@rails/activestorage"
 // TODO: Delete once all attachments are finished
export default class extends Controller {
  static targets = ['icon', 'preview', 'field'];

  // Direct upload to S3 on file select
  uploadImage(event) {
    const input = event.target
    const url = input.dataset.imageUploadUrl
    const file = input.files[0]
    const src = URL.createObjectURL(file)
    const upload = new DirectUpload(file, url, this)

    //  prevent double file submission
    input.value = ""

    // style upload
    this.previewTarget.src = src
    this.previewTarget.classList.add('direct-upload-progress')
    document.querySelector('input[type="submit"]').disabled = true

    if (this.hasIconTarget) {
      this.iconTarget.remove()
    }

    //  send it via Direct Upload
    upload.create((error, blob) => {
      if (error) {
        console.log(error)
      } else {
        this.fieldTarget.disabled = false;
        this.fieldTarget.value = blob.signed_id
        this.previewTarget.style.filter = ''
      }

      document.querySelector('input[type="submit"]').disabled = false
      this.previewTarget.classList.remove('direct-upload-progress')
    })
  }

  //  facilitate XHR comms for file upload progress
  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress",
      event => this.directUploadDidProgress(event))
  }

  directUploadDidProgress(event) {
    const el = document.querySelector('.direct-upload-progress')
    el.style.opacity = `${eventProgress(event)}%`
  }
}

function eventProgress(event) {
  return parseInt(event.loaded/event.total * 100)
}
