import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.observer = new IntersectionObserver(this.loadImages, {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    });

    this.mutationObserver = new MutationObserver(this.observeImages);

    this.observeImages();

    // Start observing the controller element for child list changes.
    this.mutationObserver.observe(this.element, { childList: true, subtree: true });
  }

  disconnect() {
    this.mutationObserver.disconnect();
    this.observer.disconnect();
  }

  observeImages = () => {
    const images = this.element.querySelectorAll("[data-lazy]");
    const bgImages = this.element.querySelectorAll("[data-bg-lazy]");

    images.forEach((img) => this.observer.observe(img));
    bgImages.forEach((bgImg) => this.observer.observe(bgImg));
  }

  loadImages = (entries, observer) => {
    entries.forEach((entry) => {

      if (entry.isIntersecting) {
        const img = entry.target;

        if (img.dataset.lazy) {
          img.src = img.dataset.lazy;
          img.onload = () => {
            img.removeAttribute("data-lazy");
            img.classList.add("fade-in");
          };
        }

        if (img.dataset.bgLazy) {
          img.classList.add("visible");
          img.removeAttribute("data-bg-lazy");
        }

        observer.unobserve(img);
      }
    });
  };
}
