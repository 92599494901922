export function insertAfter(element, referenceNode) {
  return referenceNode.parentNode.insertBefore(element, referenceNode.nextSibling);
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function formatErrorMessage(errors) {
  if (errors.length === 0) return "";
  if (errors.length === 1) return `${errors[0]}`;
  if (errors.length === 2) return `${errors.join(" and ")}`;

  const lastItem = errors.pop();
  return `${errors.join(", ")}, and ${lastItem}`;
}