import { Controller } from "stimulus";
import { DirectFileUpload } from "../src/direct_file_upload";
import { ProgressBar } from "../src/progress_bar";
import { formatBytes, insertAfter } from "../src/direct_upload_helpers";
import ApiClient from "../src/api_client";

export default class extends Controller {
  static targets = ['input', 'preview', 'progressBarPlaceholder'];

  connect() {
    if (this.hasInputTarget) {
      this.progressBar = new ProgressBar(this.progressBarPlaceholderTarget, this.inputTarget.dataset.attachmentType);
      this.removeHiddenField();
      this.bindEvents()
    }
  }

  disconnect() {
    this.unbindEvents()
  }

  bindEvents() {
    this.boundHandleProgressEvent = this.handleProgressEvent.bind(this);
    document.addEventListener('directUploadProgress', this.boundHandleProgressEvent);
  }

  unbindEvents() {
    document.removeEventListener('directUploadProgress', this.boundHandleProgressEvent);
  }

  // Rails automatically adds hidden field for has_many_attached association, so need to remove it, otherwise rails
  // will send array with empty string which will delete previous attachment when clicking update button.
  removeHiddenField() {
    const hiddenField = document.querySelector(`input[type="hidden"][name="${this.inputTarget.name}"]`)
    if (hiddenField) {
      hiddenField.remove()
    }
  }

  uploadFile() {
    this.reset()
    Array.from(this.inputTarget.files).forEach((file, index) => {
      this.progressBar.create(index);
      const validationErrors = this.validateFile(file)
      if (validationErrors.length > 0) {
        this.showValidationErrors(validationErrors, index, file.name)

        return
      }

      this.performUpload(file, index)
    });

    this.removeActualFilesBeforeSubmit();
  }

  reset() {
    this.progressBar.reset();
    this.resetHiddenFields();
  }

  performUpload(file, index) {
    const upload = new DirectFileUpload(
      file,
      this.inputTarget.dataset.directUploadUrl,
      index,
      this.attachmentType
    );

    upload.directUpload.create((error, blob) => {
      if (error) {
        alert('Something went wrong, please try later')
      } else {
        this.handleUploadSuccess(blob, file)
      }
    })
  }

  // Dont send actual files to server, because they are already uploaded to S3, only send blob_id in hidden field
  removeActualFilesBeforeSubmit() {
    this.inputTarget.value = null;
  }

  handleUploadSuccess(blob, file, index) {
    this.createHiddenBlobInput(blob)

    // May move UpdateImagePreview to child controller
    const imageUrl = this.getImageUrl(file)
    this.updateImagePreview(imageUrl) // TODO: make preview options based on options in form builder
    this.createDirectUploadFile(blob.key, file)
  }

  createDirectUploadFile(key, file) {
    const formData = this.getDirectUploadFileData(key, file)
    ApiClient.fetchRequest('/direct_upload_files', 'POST', formData)
      .then(() => {
        // Intentionally empty - no action needed upon success
      })
      .catch(error => {
        console.error('Request failed', error);
      });
  }

  getImageUrl(file) {
    return URL.createObjectURL(file)
  }

  validateFile(file) {
    let errors = []

    const maxSize = this.inputTarget.dataset.maxFileSize
    const allowedFormats = this.inputTarget.dataset.allowedFormats ? JSON.parse(this.inputTarget.dataset.allowedFormats) : [];

    if (maxSize && file.size > maxSize) {
      errors.push(`size should not be greater than ${formatBytes(maxSize)}`)
    }

    let validExtensions = Object.keys(allowedFormats).join(', ');
    if (Object.values(allowedFormats).length && !Object.values(allowedFormats).includes(file.type)) {
      errors.push(`should be one of the following types: ${validExtensions}`)
    }

    return errors
  }

  // add blob id to be submitted with the form
  createHiddenBlobInput(blob) {
    const hiddenField = document.createElement("input")
    hiddenField.setAttribute("type", "hidden")
    hiddenField.setAttribute("value", blob.signed_id)
    hiddenField.name = this.inputTarget.name
    insertAfter(hiddenField, this.inputTarget)
  }

  updateImagePreview(imageUrl) {
    if (this.hasPreviewTarget) {
      this.previewTarget.src = imageUrl
      this.previewTarget.parentNode.style.display = 'block'
    }
  }

  handleProgressEvent(event) {
    const { progress, index, fileName, fileSize, attachmentType } = event.detail;

    if (this.attachmentType === attachmentType) {
      this.progressBar.updateSuccess(progress, index, fileName, fileSize);
    }
  }

  showValidationErrors(validationErrors, index, fileName) {
    this.hidePreviewImage();
    this.progressBar.updateFailed(index, fileName, validationErrors)
  }

  hidePreviewImage() {
    if (this.hasPreviewTarget) {
      this.previewTarget.parentNode.style.display = 'none'
    }
  }

  get attachmentType() {
    return this.inputTarget.dataset.attachmentType;
  }

  resetHiddenFields() {
    const targetName = this.inputTarget.name;

    const hiddenInputs = this.element.querySelectorAll(`input[type="hidden"][name="${targetName}"]`);
    hiddenInputs.forEach(input => {
      input.remove();
    });
  }

  getDirectUploadFileData(key, file) {
    let formData = new FormData()
    formData.append('direct_upload_file[s3_key]', key)
    formData.append('direct_upload_file[file_name]', file.name)
    formData.append('direct_upload_file[content_type]', file.type)
    formData.append('direct_upload_file[byte_size]', file.size)
    formData.append('direct_upload_file[attachment_type]', this.attachmentType)
    formData.append('direct_upload_file[direct_upload_url]', this.inputTarget.dataset.directUploadUrl)

    return formData
  }
}
