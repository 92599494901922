import {Controller} from "stimulus"

export default class extends Controller {
  static targets = [ "slider", "slide", "arrowLeft", "arrowRight", "slides", "heroBg" ]
  index = 0

  connect() {
    this.scrollNumber = this.data.get('scrollNumber') || 1
  }

  slide(event) {
    event.preventDefault()
    const direction = this.calculateDirection(event.target.dataset.direction)
    this.index = this.calculateNextIndex(direction)

    // Show the slide corresponding to current index in the viewport
    this.slideTargets[this.index].scrollIntoView({
      behavior: "smooth",
      block: 'nearest'
    })

    // Update the UI (controls/background image)
    this.updateUserInterface()
  }

  calculateDirection(directionInput) {
    // Set a base direction value:  is -1 for left and 1 for right
    return directionInput.includes('arrowLeft') ? -1 : 1
  }

  calculateNextIndex(direction) {
    // Calculate the next index based on current index, scroll number and direction
    let nextIndex = this.index + (this.scrollNumber * direction)

    // If calculated index is less than 0, then reset the index to 0 (first slide)
    // ELSE If calculated index is greater than the maximum valid index
    //   (total slides - scroll number), set the index to the maximum valid index
    if (nextIndex < 0) {
      nextIndex = 0
    } else if (nextIndex > this.slideTargets.length - this.scrollNumber) {
      nextIndex = this.slideTargets.length - this.scrollNumber
    }
    return nextIndex
  }

  updateUserInterface() {
    let endIndex = this.slideTargets.length - this.scrollNumber

    // If the slider has a hero background, update it
    if (this.hasHeroBgTarget) this.updateBackgroundImage()

    // Update the UI shadows
    this.toggleShadow('shadow-left', this.index > 0);
    this.toggleShadow('shadow-right', this.index !== endIndex);

    // Update the UI arrows (disabled for now as not quite working)
    // this.toggleArrow('arrowLeft', this.index === 0);
    // this.toggleArrow('arrowRight', this.index === endIndex);
  }

  toggleShadow(selector, bool) {
    this.sliderTarget.classList[bool ? 'add' : 'remove'](selector);
  }

  toggleArrow(selector, bool) {
    this[selector + 'Target'].classList[bool ? 'add' : 'remove']('hidden');
  }

  updateBackgroundImage() {
    this.swapFadeBackground(this.slideTargets[this.index].getAttribute('data-bg-image'))
  }

  swapFadeBackground(url) {
    const element = this.heroBgTarget
    const currentUrl = element.style.getPropertyValue("--background-image")
    const urlRegex = /url\((.*?)\)/i

    // Guard against setting the same, or empty, background image
    if (urlRegex.test(currentUrl) && urlRegex.exec(currentUrl)[1] === url || url === null || url === '' ) return

    const newBackgroundImage = `url(${url})`

    element.style.transition = 'opacity 0.5s ease-out'
    element.style.opacity = '0'
    setTimeout(() => {
      element.style.setProperty("--background-image", newBackgroundImage)
      element.style.opacity = '1'
    }, 500)
  }
}
