import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['filter', 'options', 'input']

  redirect(event) {
    const target = event.target
    window.open(target.dataset.redirectUrl)
  }

  filter(event) {
    this.optionsTarget.classList.remove('hide')
    const options = this.optionsTarget.querySelectorAll('li')
    const query = this.filterTarget.value.toLowerCase().trim().split(' ') 

    if (query.length == 0) {
      options.forEach((node) => node.classList.remove('hide'))
    } else {
      options.forEach((node) => {
        if (query.every(token => node.dataset.itemTitle.toLowerCase().includes(token))) {
          node.classList.remove('hide')
        } else {
          node.classList.add('hide')
        }
      })
    }
  }

  selected(event) {
    const target = event.currentTarget
    this.inputTarget.value = target.dataset.itemId
    this.filterTarget.value = target.dataset.itemTitle
    this.optionsTarget.classList.add('hide')
  }

}