import { Controller } from 'stimulus'

export default class extends Controller {

  toggleDisplay(event) {
    document.querySelectorAll('.enrolment-modes .dates .fields').forEach((el) => {
      if (el.classList.contains(event.target.dataset.target)) {
        el.classList.remove('hide')
        el.querySelectorAll('input').forEach((input) => {
          input.disabled = false
        })
      } else {
        el.classList.add('hide')
        el.querySelectorAll('input').forEach((input) => {
          input.disabled = true
        })
      }
    })
  }
}
