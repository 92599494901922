// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs'
import "@hotwired/turbo-rails";
import Trix from "trix"

window.Trix = Trix;

document.addEventListener("trix-before-initialize", () => {
  // Trix.config.blockAttributes.default.tagName = "p"
  Trix.config.blockAttributes.default.breakOnReturn = true;

  Trix.Block.prototype.breaksOnReturn = function(){
    const attr = this.getLastAttribute();
    const config = Trix.getBlockConfig(attr ? attr : "default");
    return config ? config.breakOnReturn : false;
  };
  Trix.LineBreakInsertion.prototype.shouldInsertBlockBreak = function(){
    if(this.block.hasAttributes() && this.block.isListItem() && !this.block.isEmpty()) {
      return this.startLocation.offset > 0
    } else {
      return !this.shouldBreakFormattedBlock() ? this.breaksOnReturn : false;
    }
  };
})

import "@rails/actiontext"

import * as ActiveStorage from '@rails/activestorage'
import 'channels'
import 'controllers'
import 'stylesheets/application.scss'
import date_widget from 'src/date_widget'
import sortable_list from 'src/sortable_list'

Rails.start();
// ActiveStorage.start();

// Attach JS components on page load
document.addEventListener('turbo:load', () => {
  date_widget.attach()
  sortable_list.attach()
})
