import flatpickr from 'flatpickr'

export default { attach: attach_date_widget }

function attach_date_widget () {

  //  Attach date widgets to forms with the class .date-widget
  document.querySelectorAll('.date-widget').forEach((widget) => {
    const dataEnableTime = widget.dataset.dateWidgetEnableTime
    const enableTime = !(dataEnableTime == "0" || dataEnableTime == "false")
    const dateFormat = enableTime ? "d M Y h:i K" : "d M Y"

    if (!widget.dataset.flatpickr) {

      //  User flatpickr
      const pickr = flatpickr(widget, {
        enableTime,
        allowInput: true,
        clickOpens: false,
        dateFormat,
        parseDate: function (date) { return new Date(date) }
      })

      //  Create an icon to trigger it
      const cal = document.createElement('span')
      cal.classList.add('icon', 'calendar', 'clickable')
      cal.innerHTML = '<svg class="icon" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" /></svg>'
      cal.addEventListener('click', event => pickr.toggle())
      widget.insertAdjacentElement('afterend', cal)

      widget.dataset.flatpickr = 'attached'
    }
  })
}