import { Controller } from "stimulus";

const HIDDEN_CSS_CLASS = 'hide';
const SHOW_CSS_CLASS = 'show';
const ALERT_SUCCESS_CSS_CLASS = 'alert-success';
const ALERT_DANGER_CSS_CLASS = 'alert-danger';

export default class HelpController extends Controller {
  static targets = ['helpPreview', 'helpForm', 'mainHelpBar', 'previewButton', 'editButton',
    'formContent', 'previewContent', 'formAlert', 'previewAlert']

  connect() {
    if (this.hasHelpFormTarget) {
      this.helpFormTarget.addEventListener("ajax:success", this.onSuccess.bind(this));
      this.helpFormTarget.addEventListener("ajax:error", this.onError.bind(this));
    }
  }

  disconnect() {
    if (this.hasHelpFormTarget) {
      this.helpFormTarget.removeEventListener("ajax:success", this.onSuccess);
      this.helpFormTarget.removeEventListener("ajax:error", this.onError);
    }
  }

  onSuccess(event) {
    const [_data, _status, xhr] = event.detail;
    let response = JSON.parse(xhr.responseText);

    this.previewContentTarget.innerHTML = this.helpFormTarget.querySelector("#help_content").innerHTML;
    this.hideElement(this.helpFormTarget);
    this.showElement(this.helpPreviewTarget);
    this.showAlert(this.previewAlertTarget, response.message, ALERT_SUCCESS_CSS_CLASS);
  }

  onError(event) {
    const [_data, _status, xhr] = event.detail;
    let response = JSON.parse(xhr.responseText);
    this.showAlert(this.formAlertTarget, response.message, ALERT_DANGER_CSS_CLASS);
  }

  togglePreview(event){
    event.preventDefault();
    this.previewContentTarget.innerHTML = this.helpFormTarget.querySelector("#help_content").innerHTML;
    this.hideElement(this.formAlertTarget)
    this.hideElement(this.helpFormTarget);
    this.showElement(this.helpPreviewTarget);
  }

  toggleForm(event){
    event.preventDefault();
    this.hideElement(this.previewAlertTarget)
    this.hideElement(this.helpPreviewTarget);
    this.showElement(this.helpFormTarget);
  }

  closeHelp(event){
    if (this.hasPreviewAlertTarget)  this.previewAlertTarget.classList.remove(ALERT_SUCCESS_CSS_CLASS)
    if (this.hasFormAlertTarget) this.formAlertTarget.classList.remove(ALERT_DANGER_CSS_CLASS)
    this.mainHelpBarTarget.classList.remove("show")
  }

  hideElement(element) {
    if (element) element.classList.add(HIDDEN_CSS_CLASS)
  }

  showElement(element) {
    if (element) element.classList.remove(HIDDEN_CSS_CLASS)
  }

  showAlert(element, message, alertType) {
    element.classList.add(alertType);
    element.innerHTML = message;
    element.classList.remove(HIDDEN_CSS_CLASS);
  }
}
