import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['filterDepartmentGroup']
  static values = { context: String }

  //  an onchange event trigger for a selector to filter a chiplist
  filterParticipants(_event) {
    //  Build a query URL
    const value = this._buildParticipantQueryFilterParams(this.filterDepartmentGroupTarget.value);
    const url = new URL(`${window.location.origin}/lms/participants/list`)
    url.searchParams.append('scopes[in_association_name]', value.association);
    url.searchParams.append('scopes[in_association_id]', value.id);
    url.searchParams.append('scopes[status][active]', 1);
    if (this.hasContextValue) url.searchParams.append('context', this.contextValue)

    //  Create an event for another controller to listen for
    const filterEvent = new CustomEvent("filter-chip-list", { detail: { url: url }})

    //  Bombs away!
    window.dispatchEvent(filterEvent)
  }

  filterLanguages(event) {
    //  Build a query URL
    const url = new URL(`${window.location.origin}/lms/courses/list`)
    url.searchParams.append('language', event.target.value)
    url.searchParams.append('ignore_bundles', true)

    // Create an event for another controller to listen for
    const filterEvent = new CustomEvent("filter-chip-list", { detail: { url: url }})

    // //  Bombs away!
    window.dispatchEvent(filterEvent)
  }

  _buildParticipantQueryFilterParams(value) {
    const obj = value.split('-')
    return {
      association: obj[0],
      id: obj[1],
    }
  }
}
