import { Controller } from 'stimulus'

export default class extends Controller {
  handleEvent({ detail: { action } }) {
    if (action == 'remove') this.#removeNotice()
  }

  #removeNotice() {
    const notice = document.querySelector('#notice')
    notice.innerHTML = ''
  }
}
