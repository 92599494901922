import { Controller } from 'stimulus'
import { useDispatch } from 'stimulus-use'

export default class extends Controller {
  static values = { eventPrefix: String, pushState: Boolean }

  connect() {
    this.pushStateValue = this.hasPushStateValue ? this.pushStateValue : true

    const options = {
      eventPrefix: this.hasEventPrefixValue ? this.eventPrefixValue : true,
    }
    useDispatch(this, options)
  }

  handleDispatch() {
    this.pushState()
    this.dispatchEvent()
  }

  pushState() {
    if (!this.pushStateValue) return

    const formData = new FormData(this.element)
    const searchParams = new URLSearchParams(formData)
    const url = new URL(this.element.action)
    url.search = searchParams.toString()

    history.pushState({}, '', url)
  }

  dispatchEvent() {
    this.dispatch('submittingFrom')
  }

  submitForm() {
    this.handleDispatch()
    Turbo.navigator.submitForm(this.element)
  }
}
