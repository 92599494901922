import { Controller } from 'stimulus'
import vjs from 'video.js'
import { registerPlayEvent } from "../src/video_utils";

export default class extends Controller {
  static values = {
    disabledFeatures: Array,
    id : String,
  }
  static targets = ['video']

  connect() {
    this.setPlayer()
  }

  setPlayer = () => {
    this.alreadyPlayed = false;
    // Workaround to load the controller only once and be able to dispose the video player
    // leaving the video tag for cache
    this.clonedVideo = this.videoTarget.cloneNode(true)
    this.player = vjs(this.videoTarget)
    this.player.src({
      src: this.videoSource(),
      type: this.videoType(),
      withCredentials: true
    })
    this.player.ready(() => {
      this.dispatchVjsInitialized()
    })

    this.applyRanges()

    const idValue = this.videoTarget.dataset.videojsIdValue

    console.log("Registering event via VideoJS Controller:", idValue)

    this.player.on('play', () => {
      console.log("VideoJS Play Event:", idValue);
      if (!this.alreadyPlayed) {
        console.log("Registering play event for video:", idValue);
        this.alreadyPlayed = true;
        registerPlayEvent(idValue);
      }
    });
  }

  disconnect() {
    this.player.dispose()
    this.element.insertAdjacentElement('afterbegin', this.clonedVideo)
  }

  dispatchVjsInitialized() {
    const vjsInitialized = new CustomEvent('vjsInitialized', {
      detail: { player: this.player },
    })

    window.dispatchEvent(vjsInitialized)
  }

  sourceElement() {
    return this.element.querySelector('source')
  }

  videoSource() {
    return this.sourceElement().getAttribute('src')
  }

  videoType() {
    return this.sourceElement().getAttribute('type')
  }

  applyRanges() {
    const params = new URLSearchParams(window.location.search);
    const videosettings = params.get('videosettings');
    this.loop = params.get('loop');

    if (!videosettings) { return; }

    this.ranges = videosettings.split('_').map(range => range.split('-'));
    this.currentRange = 0;

    this.player.currentTime(this.ranges[this.currentRange][0]);

    this.player.on('timeupdate', this.onTimeUpdate);
    this.player.on('play', this.onPlay)
  }

  goToNextRange = () => {
    this.currentRange += 1;

    if (this.currentRange === this.ranges.length) {
      this.handleEndOfRanges();
    } else {
      this.player.currentTime(this.ranges[this.currentRange][0]);
    }
  }

  handleEndOfRanges = () => {
    this.loop === '1' ? this.loopVideo() : this.player.pause();
  }

  onTimeUpdate = () => {
    if (this.player.paused()) { return; }

    if (this.player.currentTime() >= this.ranges[this.currentRange][1]) {
      this.goToNextRange();
    }
  }

  onPlay = () => {
    if (this.player.currentTime() >= this.ranges.at(-1)[1]) {
      this.loopVideo()
    }
  }

  loopVideo = () => {
    this.currentRange = 0;
    this.player.currentTime(this.ranges[this.currentRange][0])
  }
}
