import { Controller } from "stimulus"
import { clearHover } from "src/utils"

export default class extends Controller {
  static targets = ['icon', 'preview', 'field', 'name', 'button']

  uploadFile () {
    const droppable = this.fieldTarget.closest(".droppable")
    clearHover(droppable)

    const file = this.fieldTarget.files[0]

    if (file === undefined || file.type !== 'text/csv') return

    this.iconTarget.classList.add('hide')
    this.previewTarget.classList.remove('hide')

    this.nameTarget.innerHTML = file.name
    this.buttonTarget.disabled = false
  }

  filterData(event) {
    if (event.target.nodeName === 'INPUT') return

    switch (event.target.closest('div').dataset['filter']) {
      case 'without-issue':
        this.toggleElements('.preview-table tr:not(.process-error)')
        break;
      case 'add':
        this.toggleElements('.preview-table tr.process-add')
        break;
      case 'update':
        this.toggleElements('.preview-table tr.process-update')
        break;
      case 'corrected':
        this.toggleElements('.preview-table tr.process-corrected')
        break;
      case 'wont-import':
        this.toggleElements('.preview-table tr.process-error')
        break;
    }
  }

  toggleElements(query) {
    const elements = document.querySelectorAll(query)

    for (let element of elements) {
      if (element.classList.length === 0) continue

      element.classList.toggle('hide')
    }
  }
}
