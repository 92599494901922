import { Controller } from 'stimulus'
import Trix from "trix"
import {post} from "@rails/request.js"

export default class extends Controller {
  static values = {
    components: Array,
    snippets: Object
  }

  get editor() {
    return this.element.querySelector("trix-editor").editor
  }

  connect() {
    // Grab a reference to the toolbar(s) on the page.
    const toolbar = this.element.querySelector("trix-toolbar")
    const dialogs = this.element.querySelector(".trix-dialogs")
    const components = this.componentsValue

    // HTML buttons and dialogs
    components.forEach((component) => {
      let componentHtml = null
      let dialogHtml = null
      let toolbarTarget = '.trix-button-group--file-tools'

      if (component === 'snippets') {
        componentHtml = this._snippetsButton()
        dialogHtml = this._snippetsDialog()
      }

      if (component === 'iframe') {
        componentHtml = this._iframeButton()
        dialogHtml = this._iframeDialog()
      }

      if(componentHtml) {
        toolbar.querySelector(toolbarTarget).insertAdjacentHTML("beforeend", componentHtml)
      }

      if(dialogHtml) {
        dialogs.innerHTML += dialogHtml
      }
    })
  }

  toggleButton(targetID) {
    const button = this.element.querySelector(`#${targetID}`)
    button.classList.toggle("trix-active")
  }

  toggleDialog(event) {
    const toolbarBtn = this.element.querySelector('#' + event.currentTarget.id)
    const dialog = this.element.querySelector('[data-trix-dialog="' + event.currentTarget.dataset.dialog + '"]')
    const input = this.element.querySelector('[name="' + event.currentTarget.dataset.dialog + '"]')

    if (dialog.classList.contains("trix-active")) {
      delete dialog.dataset.trixActive
      if(input) input.setAttribute("disabled", "disabled")
    } else {
      dialog.dataset.trixActive = ""
      if(input) input.removeAttribute("disabled")
    }

    dialog.classList.toggle("trix-active")
    this.toggleButton(toolbarBtn.id)
  }

  _snippetsButton() {
    return "<button type='button' class='trix-button trix-button--icon trix-button--icon-snippets' data-dialog='snippets' data-action='click->text-editor#toggleDialog' title='Insert Snippet' tabindex='-1' id='snippets-btn'>Snippets</button>"
  }

  _snippetsDialog() {
    const snippets = this.snippetsValue
    const snippetsRequired = snippets.required_placeholders
    const snippetsOptional = snippets.optional_placeholders
    let requiredHtml = ''
    let optionalHtml = ''

    snippetsRequired.forEach(function (snippet) {
      requiredHtml += '<input type="button" class="trix-button trix-button--dialog" value="' + snippet + '" data-action="click->text-editor#insertSnippet" >'
    })

    snippetsOptional.forEach(function (snippet) {
      optionalHtml += '<input type="button" class="trix-button trix-button--dialog" value="' + snippet + '" data-action="click->text-editor#insertSnippet" >'
    })

    return `<div class="trix-dialog trix-dialog--link snippet-options gap-2" data-trix-dialog="snippets" data-trix-dialog-attribute="snippets">
      <div class="trix-dialog__link-fields form-row gap-2">
        <strong>Required:  </strong>
        <div class="trix-button-group required-placeholders">
          ${requiredHtml}
        </div>
      </div>
      <div class="trix-dialog__link-fields form-row gap-2">
        <strong>Optional:  </strong>
        <div class="trix-button-group optional-placeholders">
          ${optionalHtml}
        </div>
      </div>
    </div>`
  }

  insertSnippet(event) {
    const snippet = event.currentTarget.value
    const dialog = this.element.querySelector('[data-trix-dialog="snippets"]')

    this.editor.insertString(snippet)

    this.element.focus()
    this.toggleButton('snippets-btn')
  }

  _iframeButton() {
    return "<button type='button' class='trix-button  trix-button--icon trix-button--icon-iframe' data-dialog='iframeUrl' data-action='click->text-editor#toggleDialog' title='Insert iFrame' tabindex='-1' id='iframe-btn'>IFrame</button>"
  }

  _iframeDialog() {
    return `<div class="trix-dialog trix-dialog--link" data-trix-dialog="iframeUrl" data-trix-dialog-attribute="iframeUrl">
      <div class="trix-dialog__link-fields">
        <input type="url" name="iframeUrl" class="trix-input trix-input--dialog" placeholder="Enter a iFrame URL…" aria-label="iFrame URL" required="" data-trix-input="" disabled="disabled">
        <div class="trix-button-group">
          <input type="button" class="trix-button trix-button--dialog" value="Insert" data-action='click->text-editor#attachIframe' >
        </div>
      </div>
    </div>`
  }


  insertIframe(iframeAttachment) {
    this.attachment = new Trix.Attachment(iframeAttachment)
    this.editor.insertAttachment(this.attachment)
    this.element.focus()
  }

  async attachIframe(event) {
    const iframeUrl = this.element.querySelector('[name="iframeUrl"]').value

    this.toggleButton('iframe-btn')

    const response = await post('/lms/iframes',
        { body: JSON.stringify({url: iframeUrl}) }
    )

    if (response.ok) {
      const iframeAttachment = await response.json
      this.insertIframe(iframeAttachment)
    } else {
    }
  }
}
