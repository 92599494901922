import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['switch']

  preventMultipleSelections = ({ detail: { switchElement } }) => {
    if (
      this.switchTargets.includes(switchElement) &&
      switchElement.switch.checkboxInputTarget.checked
    ) {
      this.switchTargets
        .filter(
          (switchTarget) =>
            switchTarget != switchElement &&
            switchTarget.switch.checkboxInputTarget.checked
        )
        .forEach((switchTarget) => {
          switchTarget.switch.toggle()
        })
    }
  }
}
