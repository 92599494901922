import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'content', 'list', 'form']

  // There is nothing to stop the inclusion of multiple notes for different objects
  //  in the same view. Notes are form agnostic and can be placed outside forms.
  update(event) {
    const value = this.contentTarget.value

    //  send it to controller if content is present
    if (value && value.trim() != '') {
      this.formTarget.dispatchEvent(new CustomEvent('submit', { bubbles: true }))

      // clear value after submit
      this.contentTarget.value = ''
    }
  }
}
