import { Controller } from 'stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['dependent', 'source']
  static values = {
    url: String,
    additionalParams: Object,
    disableDependent: Boolean,
  }

  connect() {
    if (!!this.sourceTarget && this.sourceTarget.value !== '') {
      this.change({ target: this.sourceTarget })
    }
    if (
      !!this.dependentTarget &&
      this.dependentTarget.value == '' &&
      this.disableDependentValue
    ) {
      this.dependentTarget.disabled = true
    }
  }

  async change(event) {
    if (!this.sourceTarget || !this.dependentTarget) return

    let params = new URLSearchParams()
    params.append('target', this.dependentTarget.id)
    this.#appendAdditionalParams(params, this.additionalParamsValue)
    const url = `${this.#formattedUrl(this.urlValue, event.target.value)}?${params}`

    this.#clearDependantAndNotice()
    const response = await get(url, { responseKind: 'turbo-stream' })

    this.dependentTarget.disabled = false
  }

  appendAdditionalParamsValues({ detail }) {
    this.additionalParamsValue = { ...this.additionalParamsValue, ...detail }
  }

  #appendAdditionalParams(params, additionalParams) {
    Object.entries(additionalParams).forEach(([paramName, value]) => {
      if (!!value) params.append(paramName, value)
    })
  }

  #formattedUrl(url, id) {
    return url.replace(':id', id)
  }

  #clearDependantAndNotice() {
    this.dependentTarget.innerHTML = ''

    const noticeEvent = new CustomEvent('noticeEvent', {
      detail: { action: 'remove' },
    })
    window.dispatchEvent(noticeEvent)
  }
}
