import { Controller } from 'stimulus'
import { wasVideoViewed } from '../src/utils'
import { post } from '@rails/request.js'

export default class extends Controller {
  static targets = ['videoJs']
  static values = {
    completeUrl: String,
  }

  start = ({ detail: { player } }) => {
    if (!player) return

    this.player = player
    this.player.on('timeupdate', this.videoHandler)
    this.player.controlBar.progressControl.disable()
  }

  disconnect() {
    if (this.player) this.player.off('timeupdate', this.videoHandler)
  }

  videoHandler = (_event) => {
    if (this.player.paused()) return

    const seconds = Math.floor(this.player.currentTime())
    const duration = this.player.duration()
    if (wasVideoViewed(duration, seconds)) this.completeVideoElement()
  }

  async completeVideoElement() {
    if (this.hasCompleteUrlValue && !this.videoViewed) {
      this.videoViewed = true

      await post(this.completeUrlValue, { responseKind: 'turbo-stream' })
    }
  }
}
