import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.thead = this.element.querySelector('thead')
    this.tbody = this.element.querySelector('tbody')
    this.tfoot = this.element.querySelector('tfoot')
    this.headRows = this.thead.querySelectorAll('tr')
    this.bodyRows = this.tbody.querySelectorAll('tr')
    this.footRows = this.tfoot.querySelectorAll('tr')
    this.loader = document.querySelector('.loader')
    this.setDynamicDimension()
  }

  async setDynamicDimension() {
    this.setRowsHeight()
    this.setBodyCellsWidth()
    this.loader?.classList?.add('hide')
    this.element.classList.remove('invisible')
  }

  setRowsHeight() {
    this.bodyRows.forEach((bodyRow, index) => {
      let bodyRowHeight = parseFloat(window.getComputedStyle(bodyRow).height)

      // round up the height to fix unaligned table when printing
      if (!Number.isInteger(bodyRow)) bodyRowHeight = Math.ceil(bodyRowHeight)

      this.headRows[index].style.height = `${bodyRowHeight}px`
      this.footRows[index].style.height = `${bodyRowHeight}px`
      bodyRow.style.height = `${bodyRowHeight}px`
    })
  }

  setBodyCellsWidth() {
    const bodyWidth = this.tbody.clientWidth
    if (bodyWidth > this.bodyRows[0].offsetWidth) {
      const width = bodyWidth / this.bodyRows[0].querySelectorAll('td').length
      this.tbody.querySelectorAll('td').forEach((td) => {
        td.style.width = `${width}px`
      })
    }
  }

  showLoader() {
    this.element.classList.add('invisible')
    this.loader?.classList?.remove('hide')
  }
}
