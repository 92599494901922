import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['question', 'editor', 'answers']

  toggleSettings(event) {
    this.editorTarget.classList.toggle('hide')
    event.currentTarget.classList.toggle('selected')
    event.currentTarget.scrollIntoView({ behavior: 'smooth' })
  }

  toggleAnswers(event) {
    this.answersTarget.classList.toggle('hide')
    event.currentTarget.classList.toggle('selected')
    event.currentTarget.scrollIntoView({ behavior: 'smooth' })
  }

  toggleSampleablility(event) {
    event.currentTarget.classList.toggle('selected')
    let input = this.questionTarget.querySelector("input[name*='sampleable']")
    let value = +(input.value === 'true')
    input.value = Boolean(Math.abs(value - 1))
  }

  toggleHidden(event) {
    event.currentTarget.classList.toggle('selected')
    let input = this.questionTarget.querySelector("input[name*='hidden']")
    let value = +(input.value === 'true')
    input.value = Boolean(Math.abs(value - 1))
  }

  removeQuestion() {
    let confirmation = confirm('Are you sure you want to delete this question?')

    if (confirmation) {
      this.questionTarget.querySelector("input[name*='_destroy']").value = 1
      this.questionTarget.classList.add('hide')

      const elementRemovedEvent = new CustomEvent('elementRemoved', {
        detail: { element: this.questionTarget },
      })
      window.dispatchEvent(elementRemovedEvent)
    }
  }

  addAnswer(event) {
    event.preventDefault()

    let li = document.createElement('li')
    li.classList.add('answer')
    li.innerHTML = event.currentTarget.dataset.formPrepend.replace(
      /replace-child-record/gi,
      new Date().getTime()
    )

    this.questionTarget.querySelector('ul.answers').appendChild(li)
  }

  removeAnswer(event) {
    let confirmation = confirm('Are you sure you want to delete this answer?')

    if (confirmation) {
      let answer = event.currentTarget.parentNode.parentNode
      answer.querySelector("input[name*='_destroy']").value = 1
      answer.classList.add('hide')

      const elementRemovedEvent = new CustomEvent('elementRemoved', {
        detail: { element: answer },
      })
      window.dispatchEvent(elementRemovedEvent)
    }
  }

  toggleCorrectAnswer(event) {
    let answer = event.currentTarget.parentNode.parentNode
    let element = answer.querySelector("input[name*='correct']")

    // toggle the answer#correct boolean value
    let value = +(element.value === 'true')
    answer.querySelector("input[name*='correct']").value = Boolean(Math.abs(value - 1))

    // toggle the buttons
    answer.querySelector('.correct-answer').classList.toggle('hide')
    answer.querySelector('.incorrect-answer').classList.toggle('hide')
  }
}
