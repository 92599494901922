import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.transition = JSON.parse(JSON.stringify(this.containerTarget.style.transition));
    this.init();
  }

  init() {
    if (window.screen.width < 576) {
      if (!this.isHided(this.nextTarget)) this.nextTarget.classList.add('hide');
      if (!this.isHided(this.prevTarget)) this.prevTarget.classList.add('hide');
      this.distanceValue = 0;
      this.itemsViewedValue = 0;
      this.applyTransition();
    } else {
      this.setInitialValues();
      if (this.hasNext() && this.isHided(this.nextTarget))
        this.nextTarget.classList.remove('hide');
      if (this.hasPrev() && this.isHided(this.prevTarget))
        this.prevTarget.classList.remove('hide');
      this.verifyResizing();
    }
    this.prevWindow = JSON.parse(JSON.stringify(window.screen.width));
  }

  verifyResizing() {
    if (this.prevWindow < 576) {
      this.containerTarget.scrollLeft = 0;
    }
    if (this.hasPrev()) {
      this.goToChildren();
      this.containerTarget.style.transition = `none`;
      setTimeout(() => {
        this.containerTarget.style.transition = this.transition;
      }, 500);
    }
  }
}
