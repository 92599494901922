import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['menu', 'table', 'thead', 'wrapper']

  connect() {
    this.thead = this.theadTarget
    this.menu = this.menuTarget
    this.headers = Array.from(this.tableTarget.querySelectorAll('th'))
    this.cells = Array.from(this.tableTarget.querySelectorAll('th, td'))
    this.numColumns = this.headers.length
    this.cells.forEach((cell, index) => {
      cell.setAttribute('data-column-index', index % this.numColumns)
      cell.setAttribute('data-showable', 'true')
    })

    this.headers.forEach((th, index) => {
      const checkbox = this.createListElement(index, th)
      this.menuTarget.appendChild(checkbox)
      checkbox.addEventListener('change', this.toggleColumn.bind(this))
    });

    this.theadTarget.addEventListener('contextmenu', this.toggleTableMenu.bind(this))
  }

  disconnect() {
    this.theadTarget.removeEventListener('contextmenu', this.toggleTableMenu.bind(this))
  }

  createListElement(index, th) {
    const li = document.createElement('li')
    const label = document.createElement('label')
    const checkbox = document.createElement('input')

    checkbox.setAttribute('type', 'checkbox')
    checkbox.setAttribute('checked', 'true')
    checkbox.setAttribute('data-column-index', index)

    const text = document.createTextNode(th.textContent)
    label.appendChild(checkbox)
    label.appendChild(text)

    li.appendChild(label)
    return li
  }

  btnToggleTableMenu(event) {
    event.preventDefault()

    document.removeEventListener('click', this.documentClickHandler)

    this.menu.classList.toggle('invisible')
    event.target.classList.toggle('active')

    const rect = event.target.parentNode.closest('.card').getBoundingClientRect()
    const y = rect.top - event.currentTarget.offsetHeight - this.menu.offsetHeight //- (event.target.offsetHeight*2)
    const x = event.clientX - rect.left - this.menu.offsetWidth + (event.currentTarget.offsetWidth/2)

    this.menuTarget.style.top = y + 'px'
    this.menuTarget.style.left = x + 'px'
  }

  toggleTableMenu(event) {
    event.preventDefault();

    this.toggleMenu()

    const rect = event.target.parentNode.closest('.card').getBoundingClientRect();
    const y = event.clientY - rect.top
    const x = event.clientX - rect.left

    this.menuTarget.style.top = y + 'px'
    this.menuTarget.style.left = x + 'px'

    this.documentClickHandler = this.documentClickHandler.bind(this, event)
    document.addEventListener('click', this.documentClickHandler)
  }

  documentClickHandler(event) {
    const isClickedOutside = !this.menu.contains(event.target)

    if (isClickedOutside && !this.menu.classList.contains('invisible')) {
      this.hideMenu()
      document.removeEventListener('click', this.documentClickHandler)
    }
  }

  toggleMenu(event) {
    this.menu.classList.toggle('invisible')
  }

  hideMenu(event) {
    this.menu.classList.add('invisible')
  }

  toggleColumn(event) {
    const index = event.target.getAttribute('data-column-index')
    event.target.checked ? this.showColumn(index) : this.hideColumn(index)
    this.hideMenu()
  }

  showColumn(index) {
    this.cells
      .filter(cell => cell.getAttribute('data-column-index') === index)
      .forEach(cell => {
        cell.classList.remove('hidden')
        cell.setAttribute('data-showable', 'true')
      });
  }

  hideColumn(index) {

    this.cells
      .filter(cell => cell.getAttribute('data-column-index') === index)
      .forEach(cell => {
        cell.classList.add('hidden')
        cell.setAttribute('data-showable', 'false')
      });

    const numHiddenCols = this.headers.filter(th => th.getAttribute('data-showable') === 'false').length
    if (numHiddenCols === this.numColumns - 1) {
      const showableColumnIndex = this.menu.querySelector('[data-showable="true"]').getAttribute('data-column-index')
      const checkbox = this.menu.querySelector(`[type="checkbox"][data-column-index="${showableColumnIndex}"]`)
      checkbox.setAttribute('disabled', 'true')
    }
  }
}
