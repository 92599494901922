// Appends a comma seperated id string to a target hidden_input
export function addSelectionId(value, inputTarget) {
  // if inputTarget is a dom element, is assigned to input. Otherwise, tries to find it in the document
  let input = !inputTarget.nodeType ? document.querySelector(inputTarget) : inputTarget

  let match = value.match(/\D*(\d+)$|^\d+$/)
  if (match) value = match[1]

  input.value = input.value
    .concat(',', value)
    .split(',')
    .filter((value, index, self) => {
      return self.indexOf(value) === index && value.length > 0
    })
    .join(',')
}

// Removes a comma seperated id string from a target hidden_input
export function removeSelectionId(value, inputTarget) {
  // if inputTarget is a dom element, is assigned to input. Otherwise, tries to find it in the document
  let input = !inputTarget.nodeType ? document.querySelector(inputTarget) : inputTarget

  let match = value.match(/\D*(\d+)$|^\d+$/)
  if (match) value = match[1]

  input.value = input.value
    .split(',')
    .filter((id) => { return id !== value })
    .join(',')
}

//  Matches file type to a list of accepts formats
export function isValidFileType(items, accepts) {
  const isFile = (item) => {
    return item.kind == 'file' || item.constructor.name == 'File'
  }

  const isPermitted = (item) => {
    return accepts.split(',').some((type) => {
      //  accept="*"
      if (type === '*') return true

      //  acccept="image/*"
      if (type.match(/^(\w+\/)\*/)) return item.type.startsWith(type.slice(0,-1))

      //  accept="application/pdf"
      return type.trim() === item.type
    })
  }

  //  convert different collection types into an array
  if (items.constructor.name !== Array)
    items = Array.from(items)

  return items.every((item) => {
    return isFile(item) && isPermitted(item)
  })
}

// Checks the file size is below the limit
export function lessThanMaxSizeLimit(items, maxFileSize) {
  const fileSizeAllowed = (item) => {
    if(item.size <= maxFileSize) {
      return true
    }
  }

  //  convert different collection types into an array
  if (items.constructor.name !== Array)
    items = Array.from(items)

  return items.every((item) => {
    return fileSizeAllowed(item)
  })
}

export function getMetaValue(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`)
  return element.getAttribute('content')
}

export function clearHover(element) {
  const el = element.querySelector('.drop')
  el.classList.remove('hovering')
  el.classList.remove('not-permitted')
}

export function disableForm(form) {
  form.disabled = true
  form.querySelectorAll("input[type=submit]").forEach(input => { input.disabled = true })
}

export function enableForm(form) {
  form.disabled = false
  form.querySelectorAll("input[type=submit]").forEach(input => { input.disabled = false })
}

export function isFileImage(file) {
  return file && file.type.split('/')[0] == 'image'
}

export function wasVideoViewed(duration, seconds) {
  const completion = 0.8 // 80% of the video
  const secondsToComplete = duration * completion

  return seconds >= secondsToComplete
}
