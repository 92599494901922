export default class ApiClient {
  static turboFetchRequest(url, method, body, additionalHeaders = {}) {
    const headers = this._turboFetchHeaders(additionalHeaders)

    fetch(url, { method, body, headers })
      .then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html))
      .catch(error => alert('Something went wrong. Please try later.'));
  }

  static _turboFetchHeaders(additionalHeaders) {
    return {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      'Accept': 'text/vnd.turbo-stream.html',
      ...additionalHeaders
    };
  }

  static fetchRequest(url, method, body, additionalHeaders = {}) {
    const headers = this._fetchHeaders(additionalHeaders)

    return fetch(url, { method, body, headers })
      .then(response => { return response.json() })
      .catch(error => console.error('Something went wrong. Unable to contact server.'));
  }

  static _fetchHeaders(additionalHeaders) {
    return {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      'Accept': 'application/json', // or other content types as needed
      ...additionalHeaders
    };
  }
}
