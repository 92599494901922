import { Controller } from 'stimulus'

export default class extends Controller {
  update(event) {
    let url = new URL(window.location.href)
    let params = new URLSearchParams({ lang: event.target.value })
    url.search = params

    window.location.href = url
  }
}