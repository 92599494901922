import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['participant']
  redirectTo() {
    const participantId = this.participantTarget.dataset["participantId"]
    // TODO: redirect to Participant Enrolment Completions page
    window.location.href = "#";
  }
}
