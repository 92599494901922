import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['typeField', 'licenseField']

  connect() {
    if (!!this?.typeFieldTarget.value) {
      this.#changeLicenseField(this.typeFieldTarget.value)
    }
  }

  taskTypeChange(event) {
    this.#changeLicenseField(event.target.value)
  }

  #changeLicenseField(taskType) {
    if (this.#isLicenseTargetType(taskType)) {
      this.licenseFieldTarget.classList.remove('hide')
    } else {
      this.licenseFieldTarget.classList.add('hide')
    }
  }

  #isLicenseTargetType(taskType) {
    return ['raise_invoice', 'chase_payment', 'chase_renewal'].includes(taskType)
  }
}
