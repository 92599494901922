import { Controller } from "stimulus"
import { useClickOutside } from "stimulus-use"

export default class extends Controller {
    static targets = [ "toggleable" ]

    connect() {
        useClickOutside(this)
    }

    toggle() {
        this.toggleableTarget.classList.toggle('is-active')
    }

    clickOutside({ target }) {
        this.toggleableTarget.classList.remove('is-active')
    }
}
