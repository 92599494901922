import { Controller } from 'stimulus';
// modal controller based on https://github.com/excid3/tailwindcss-stimulus-components/blob/master/src/toogle.js

export default class extends Controller {
  static targets = ['toggleable']
  static values = { open: Boolean, scrollInto: Boolean }

  connect() {
    this.toggleClass = this.data.get('class') || 'hide'
  }

  toggle(event) {
    if (event.target.getAttribute('type') != 'checkbox') {
      event.preventDefault()
    }

    this.openValue = !this.openValue
  }

  hide(event) {
    event.preventDefault()

    this.openValue = false
  }

  show(event) {
    event.preventDefault()

    this.openValue = true
  }

  openValueChanged() {
    if (!this.toggleClass) return

    this.toggleableTargets.forEach((target) => {
      target.classList.toggle(this.toggleClass)
    })

    if (this.scrollIntoValue) this.scrollIntoView()
  }

  scrollIntoView() {
    // Scroll into first target
    this.toggleableTarget.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}
