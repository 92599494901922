import { formatBytes, formatErrorMessage } from "./direct_upload_helpers";

export class ProgressBar {
  constructor(placeholder, attachmentType) {
    this.placeholder = placeholder;
    this.attachmentType = attachmentType;
  }

  updateSuccess(progress, index, fileName, fileSize) {
    const progressBarElement = this.getProgressBarElement(index)
    const progressBarPercentage = this.getProgressPercentageElement(index)
    const meta = this.getMetaElement(index);

    progressBarElement.closest('.progress-bar').classList.add('valid')
    progressBarElement.closest('.progress-bar').classList.remove('invalid')
    meta.textContent = `${fileName} (${formatBytes(fileSize)})`;

    progressBarElement.style.width = `${progress}%`;
    progressBarPercentage.textContent = `${Math.round(progress)}%`;
  }

  updateFailed(index, fileName, errors) {
    const progressBarElement = this.getProgressBarElement(index);
    const progressBarPercentage = this.getProgressPercentageElement(index)
    const meta = this.getMetaElement(index);

    progressBarElement.closest('.progress-bar').classList.add('invalid');
    progressBarElement.closest('.progress-bar').classList.remove('valid');
    meta.innerHTML = `${fileName} ${formatErrorMessage(errors)}`;

    progressBarPercentage.innerHTML = 'FAILED';
    progressBarElement.style.width = '100%';
  }

  create(index) {
    const container = this.placeholder;
    const attachmentType = this.attachmentType;
    const progressBarContainer = `progress-bar-container-${attachmentType}-${index}`

    const progressBarHtml = `
      <div id="progres-bar-placeholder-${attachmentType}">
        <div class="progress-bar-container progress-bar-container-${attachmentType}" id="${progressBarContainer}">
          <div class="progress-bar">
            <div class="icon">
               <!-- Default Icon (Visible in normal state) -->
               ${this.defaultIcon}
               <!-- Error Icon (Hidden by default, visible on .invalid class) -->
               ${this.errorIcon}
             </div>
            <div id="meta-${attachmentType}-${index}" class="meta"></div>
            <div class="progress-display" id="progress-bar-${attachmentType}-${index}" style="width:0"></div>
            <div class="progress-percentage" id="progress-percentage-${attachmentType}-${index}"></div>

          </div>
        </div>
      </div>
    `;

    container.insertAdjacentHTML('beforeend', progressBarHtml);
  }

  reset() {
    this.placeholder.innerHTML = '';
  }

  getProgressBarElement(index) {
    return document.getElementById(`progress-bar-${this.attachmentType}-${index}`);
  }

  getMetaElement(index) {
    return document.getElementById(`meta-${this.attachmentType}-${index}`);yy
  }

  getProgressPercentageElement(index) {
    return document.getElementById(`progress-percentage-${this.attachmentType}-${index}`);
  }

  get defaultIcon() {
    return `<svg class="w-6 h-6 icon-default" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
            </svg>`
  }

  get errorIcon() {
    return `<svg class="w-6 h-6 icon-error" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
               <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
            </svg>`

  }
}
