import { Controller } from 'stimulus'

//  Controller for themed checkbox toggles
export default class extends Controller {
  static targets = ['checkboxInput', 'toggle']

  connect() {
    if (
      this.hasCheckboxInputTarget &&
      this.hasToggleTarget &&
      this.checkboxInputTarget.checked
    ) {
      this.toggleTarget.classList.add('active')
    }
    this.element[this.identifier] = this
  }

  changeAllowed() {
    return this.hasToggleTarget &&
        this.toggleTarget.dataset.disabled != 'disabled' &&
        this.toggleTarget.dataset.readonly != 'readonly'
  }

  toggleButton(event) {
    event.preventDefault()

    this.toggle()
    this.dispatchCustomEvent()
  }

  toggle() {
    if (this.changeAllowed()) {
      this.toggleTarget.classList.toggle('active')
      this.checkboxInputTarget.checked = !this.checkboxInputTarget.checked
    }
  }

  dispatchCustomEvent() {
    if (this.changeAllowed()) {
      const changeEvent = new CustomEvent('checkboxChanged', {
        detail: { switchElement: this.element },
      })

      window.dispatchEvent(changeEvent)
    }
  }
}
