import { Controller } from 'stimulus'

export default class extends Controller {

    connect() {
        document.querySelectorAll('.participant-filter .filters .cool-toggle').forEach((el) => {
            el.dataset.action += ' click->participant-filter#submit'
        })
    }

    submit(event) {
        try {
            //  it's an input element
            event.target.form.submit()
        } catch (e) {
            //  or it's a label
            event.currentTarget.querySelector('input').form.submit()
        }
    }
}
