import Sortable from 'sortablejs'

export default { attach: attach_sortable_list }

function attach_sortable_list () {
  //  Attache Sortable lists
  document.querySelectorAll('.sortable').forEach((list) => {

    if (!list.dataset.sortable) {
      Sortable.create(list, {
        animation: 150,
        handle: '.grab',      
        onEnd: () => {

          //  Get the list element IDs
          let elements = []
          list.childNodes.forEach(el => {
            if (el.dataset) {
              elements.push(el.dataset.elementId)
            }
          })

          //  Send the new list to the server
          fetch(list.dataset.url,{
            method: 'POST',
            body: JSON.stringify({ sequence: elements }),
            headers: {
              'Content-Type': 'application/json',
              'X-Requested-With': 'XMLHttpRequest',
              'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
          }).then(response => {
            response.json();
          })
        }
      })

      list.dataset.sortable = 'attached'     
    }

  })
}