import { Controller } from 'stimulus'
import { getMetaValue } from 'src/utils'

export default class extends Controller {
  static targets = ['content']
  static values = { isEnrollable: Boolean }

  connect() {
    const chips = document.querySelectorAll('.selected')

    for (let chip of chips) {
      if (chip.dataset.chipTarget === 'bundle') {
        const chipType = chip.dataset.chipType

        this.fetchUnits(chip).then((units) => {
          for (let unit of units) {
            const unitChip = document.querySelector(`[data-id="${chipType}_${unit.id}"]`)
            if (!!unitChip) unitChip.classList.add('disabled', 'in-bundle')
          }
        })
      }
    }
  }

  unitNames(event) {
    // Don't change the status of the chip if the user is attempting
    // to view the course bundle details.
    event.stopPropagation()

    const chip = event.target.closest('.chip')

    this.fetchUnits(chip).then(units => {
      let content = "<ul>"

      for (let unit of units) {
        content += `<li>${unit.title}</li>`
      }

      content += "</ul>"

      this.contentTarget.innerHTML = content
    })
  }

  filterUnitBundle(event) {
    if (this.checkingDetails(event)) return

    const chip = event.target.closest('.chip')
    const originalChip = Array.from(document.querySelectorAll(`[data-id="${chip.dataset.id}"]`)).pop()

    if (chip.dataset.chipTarget !== 'bundle') return

    this.fetchUnits(originalChip).then(units => {
      for (let unit of units) {
        const chipType = originalChip.dataset.chipType
        const unitChip = document.querySelector(`[data-id="${chipType}_${unit.id}"]`)

        if (!unitChip) continue

        if (Array.from(originalChip.classList).includes('selected')) {
          unitChip.classList.add('disabled', 'in-bundle')
        } else {
          unitChip.classList.remove('disabled', 'in-bundle')
        }
      }
    })
  }

  fetchUnits(chip) {
    const chipType = chip.dataset.chipType
    const bundleId = chip.dataset.id.split('_').at(-1)

    return fetch(`/lms/${chipType}_bundles/${bundleId}/${chipType}s`, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': getMetaValue('csrf-token')
      }
    }).then(response => response.json())
      .then(units => { return units })
  }


  checkingDetails(event) {
    return event.target.id === 'bundle-details' ||
      event.target.closest('svg')?.id === 'bundle-details' ||
      event.target.classList.contains('modal')
  }
}
