import { Controller } from 'stimulus'
import { registerPlayEvent } from "../src/video_utils";
export default class extends Controller {
  static targets = ['container', 'videojs', 'youtube']

  connect() {
    this.alreadyPlayed = false;

    const closeVideo = (container) => {
      //  pause the video.js player
      this.videojsTarget.player.pause()

      //  refresh the youtube iframe to stop it
      this.youtubeTarget.src = this.youtubeTarget.src

      //  hide the players
      this.containerTarget.classList.remove('show')
    }

    // close with 'ESC'
    window.addEventListener('keyup', (event) => {
      if (event.key == 'Escape') closeVideo(this.containerTarget)
    })

    // close with click
    this.containerTarget.querySelector('.close').addEventListener('click', (event) => {
      closeVideo(this.containerTarget)
    })
  }

  async play(event) {
    event.preventDefault()
    const videoId = event.currentTarget.dataset.videoId
    const data = document.querySelector(`#video-data-${videoId}`)

    if (data.dataset.videoPlayer == 'youtube') {
      //  reload the iframe with the new youtube URL
      this.youtubeTarget.src = `https://www.youtube.com/embed/${data.dataset.youtubeId}`

      this.videojsTarget.classList.add('hide')
      this.youtubeTarget.classList.remove('hide')

    } else {
      console.debug("VideoJS Play Event:", videoId);
      //  update the video.js player
      this.videojsTarget.player.poster(data.dataset.poster)
      this.videojsTarget.player.src({
        src: data.dataset.streamingUrl,
        type: data.dataset.streamingType,
        withCredentials: true
      })
      // videoPlayer.player.play()

      this.youtubeTarget.classList.add('hide');
      this.videojsTarget.classList.remove('hide');

      console.log("Before Notification:", this.alreadyPlayed, videoId);
      if (!this.alreadyPlayed) {
        this.alreadyPlayed = true;
        registerPlayEvent(videoId);
      }
    }

    this.containerTarget.classList.add('show');
  }
}
