import {Controller} from "stimulus"
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ["submenu", "navSidebar"]

  connect() {
    this.updateSidebar();
    window.addEventListener('resize', () => {
      this.updateSidebar();
    });

    this.sidebarToggle = document.getElementById('sidenav-toggle')
    this.sidebarToggle.addEventListener('click', this.toggleSidebar.bind(this))

    this.initArrowIcon()
    this.calculateSubmenusHeight()
    this.openActiveSubmenus()
    this.collapseOpenSubmenusTarget = this.data.get('collapseOpenSubmenus')
  }

  diconnect() {
    this.sidebarToggle.removeEventListener('click', this.toggleSidebar.bind(this))
  }

  updateSidebar() {
    if (window.innerWidth < 769) {
      this.hideSidebar();
      document.body.classList.add('mobile-device')
    } else {
      this.showSidebar();
      document.body.classList.remove('mobile-device')
    }
  }

  toggleSidebar() {
    this.navSidebarTarget.classList.contains("sidebar-hidden") ? this.showSidebar() : this.hideSidebar();
  }

  hideSidebar() {
    this.navSidebarTarget.classList.add("sidebar-hidden");
    document.body.classList.remove("sidebar-show");
    document.body.classList.add('sidebar-hidden')
  }

  showSidebar() {
    document.body.classList.remove('sidebar-hidden')
    document.body.classList.add("sidebar-show");
    this.navSidebarTarget.classList.remove("sidebar-hidden");
  }

  calculateSubmenusHeight() {
    this.submenuTargets.forEach((submenu) => {
      let totalHeight = this.calculateTotalHeightIncludingMargins(submenu);
      submenu.style.height = `${totalHeight}px`;
    })
  }

  calculateTotalHeightIncludingMargins(submenu) {
    let totalHeight = 0;
    Array.from(submenu.children).forEach(child => {
      const style = getComputedStyle(child);
      const marginTop = parseFloat(style.marginTop.replace('px', ''));
      const marginBottom = parseFloat(style.marginBottom.replace('px', ''));
      const verticalMargins = marginTop + marginBottom;
      totalHeight += child.offsetHeight + verticalMargins;
    });
    totalHeight += 40; // add 40px to account for margins on the submenu itself
    return totalHeight;
  }

  openActiveSubmenus() {
    // Open submenus that have an active child link
    this.submenuTargets.forEach((submenu) => {
      if (submenu.querySelector('.active')) {
        submenu.classList.remove('collapsed')
        submenu.previousElementSibling.classList.toggle('active')

        this.initHover(submenu)
        this.animateSVG(submenu)
      }
    })
  }

  toggleSubmenu(event) {
    event.preventDefault()

    // target submenu
    const menu = event.target.parentElement.querySelector('.submenu')

    // get all submenus, then remove the one we're targeting
    this.allCloseableSubmenus = Array.from(this.submenuTargets).filter((submenu) => {
      return submenu !== menu
    })

    // collapse all submenus
    if (this.collapseOpenSubmenusTarget === 'true') this.collapseAllSubmenus()

    // init hover on submenu & toggle collapsed class
    this.initHover(menu)
    menu.classList.toggle('collapsed')
    menu.previousElementSibling.classList.toggle('active')

    // animate svg
    this.animateSVG(menu)
  }

  collapseAllSubmenus() {
    this.allCloseableSubmenus.forEach((submenu) => {
      submenu.classList.add('collapsed')

      // destroy all event listeners on submenu children
      Array.from(submenu.children).forEach((list) => {
        list.removeEventListener('mouseover', () => {})
        list.removeEventListener('mouseout', () => {})
      })
    })
  }

  animateSVG(submenu) {
    const animationToggle = submenu.previousElementSibling.classList.contains('active')
    const animate = submenu.previousElementSibling.querySelector('animate')

    animate.setAttribute(
      "values",
      animationToggle ? this.svgAnimationValues().join("; ") : this.svgAnimationValues().slice().reverse().join("; ")
    );
    animate.beginElement();
  }

  initHover(el) {
    const slide = this.insertSlide(el)

    Array.from(el.children).forEach((list) => {
      list.addEventListener('mouseover', () => {
        let parentPosition = el.getBoundingClientRect()
        let position = list.getBoundingClientRect()
        slide.classList.add('active')
        slide.style.left = position.x + 'px'
        slide.style.top = position.y - parentPosition.y + 'px'
        slide.style.height = position.height + 'px'
        slide.style.width = position.width + 'px'
      })
      list.addEventListener('mouseout', () => {
        slide.classList.remove('active')
      })
    })
  }

  insertSlide(el) {
    // Return if slide already exists
    let slide = el.querySelector('.slide')
    if (slide) return slide;

    // create div with class 'overlay' and append it to el
    slide = document.createElement('div')
    slide.classList.add('slide')
    el.prepend(slide)

    return slide
  }

  initArrowIcon() {
    this.submenuTargets.forEach((submenu) => {

      // Return if submenu already has an arrow
      if (submenu.parentNode.querySelector('.carat-icon svg')) return;

      const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      svg.setAttribute("width", "16");
      svg.setAttribute("height", "16");
      svg.setAttribute("viewBox", "0 0 100 100");
      svg.setAttribute("class", "collapsible-icon");

      const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
      path.setAttribute(
        "d",
        "M 20,35 C 20,35 36.93934,51.93934 50,65 61.702962,53.297038 80,35 80,35"
      );
      path.setAttribute("class", "arrow");

      const animate = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "animate"
      );
      animate.setAttribute("attributeName", "d");
      animate.setAttribute("dur", "0.4s");
      animate.setAttribute("repeatCount", "1");
      animate.setAttribute("fill", "freeze");
      animate.setAttribute("class", "arrowAnim");

      path.appendChild(animate);
      svg.appendChild(path);
      submenu.parentElement.querySelector('.carat-icon').appendChild(svg);

      animate.endElement();
    })

  }

  svgAnimationValues(animation_name = 'Mustache') {
    // TODO: Remove unwanted effects, once JD has decided on an animation.
    // Animation effects
    let effects = {
      Invert: ["M 20,35 50,65 80,35", "M 20,65 50,35 80:5"],
      Climb: [
        "M 20,35 50,65 80,35",
        "M 20,65 50,50 80,35",
        "M 20,65 50,35 80,65"
      ],
      Bird: [
        "M 20,35 C 20,35 36.93934,51.93934 50,65 61.702962,53.297038 80,35 80,35",
        "M 20,45 C 20,45 37.254589,42.254589 50,55 62.654565,42.345435 80,45 80,45",
        "M 20,50 C 20,50 40,50.001 50,50.001 60,50.001 80,50 80,50",
        "M 20,56.420246 C 20,56.420246 37.254589,59.165657 50,46.420246 62.654565,59.074811 80,56.420246 80,56.420246",
        "M 20,66.656854 C 20,66.656854 36.93934,49.717514 50,36.656854 61.702962,48.359816 80,66.656854 80,66.656854"
      ],
      Shoelaces: [
        "M 20,35 C 20,35 49.230054,65 50,65 50.769946,65 80,35 80,35",
        "M 40,40 C 40,40 61.140839,60 50,60 38.859161,60 60,40 60,40",
        "M 60,50 C 60,50 58.688999,50 50,50 41.311001,50 40,50 40,50",
        "M 40,60 C 40,60 40,40 50,40 60,40 60,60 60,60",
        "M 20,65 C 20,65 49.421223,35 50,35 50.578777,35 80,65 80,65"
      ],
      ClockHands: [
        "M 20,35 50,65 80,35",
        "M 11.286622,38.713395 50,60.000018 50.061728,23.074",
        "M 8.1587427,43.543855 50,55 20,25",
        "M 8.6055826,49.715 50,50.000148 8.6055826,49.715",
        "M 8.1587427,56.455761 50,44.999616 20,74.999616",
        "M 11.286622,61.286347 50,39.999724 50.061728,76.925744",
        "M 20,64.999834 50,34.999834 80,64.999834"
      ],
      Mustache: [
        "M 20,35 C 32.444363,47.444363 49.363649,65 50,65 50.636351,65 66.559179,48.440821 80,35",
        "M 20,40.82089 C 32.444363,53.265253 48.158622,60 50,60 51.841378,60 66.559179,52.619931 80,39.17911",
        "M 20,45.443249 C 32.444363,57.887612 48.158622,55 50,55 51.841378,55 66.559179,56.850871 80,43.410051",
        "M 20,50.840831 C 32.444363,63.285194 48.158622,50 50,50 51.841378,50 66.559179,63.031289 80,49.590469",
        "M 20,55.625181 C 39.489889,69.635216 48.158622,45 50,45 51.841378,45 60.687908,69.381312 80,54.374819",
        "M 20,60.625181 C 38.046868,60.625181 48.158622,40 50,40 51.841378,40 58.628408,59.374819 80,59.374819",
        "M 20,65.625181 C 33.335406,52.289775 48.158622,35 50,35 51.841378,35 66.564635,50.939454 80,64.374819"
      ]
    }

    return effects[animation_name]
  }
}
