import { Controller } from 'stimulus'

export default class extends Controller {

    toggleInput(event) {
        const id = event.target.getAttribute('data-input-id')
        const input = document.querySelector(`#${id}`)

        if (event.target.checked) {
            input.disabled = false
            input.classList.remove('disabled')
        } else {
            input.disabled = true
            input.classList.add('disabled')
        }
    }
}
