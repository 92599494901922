import { Controller } from 'stimulus'
import { post, patch } from '@rails/request.js'

export default class extends Controller {
  static targets = ["preloadForm", "form", "loader"];

  async preload(event) {
    event.preventDefault();

    this.preloadFormTarget.value = 1;
    this.loaderTarget.classList.remove('hide');
    let formData = new FormData(this.formTarget);

    const elements = Array.from(this.formTarget.elements);
    const method = elements.find(({ name }) => name === '_method')?.value || this.formTarget.method

    const methodMapper = {
      post: post,
      patch: patch
    }

    const params = [
      this.formTarget.action,
      {
        body: formData,
        responseKind: 'turbo-stream'
      }
    ];

    const fn = methodMapper[method];

    if (typeof fn === "function") fn.apply(null, params);
  }
}
