import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['description', 'item', 'icon']

  toggle(event) {
    const target = event.currentTarget
    const key = target.dataset.key
    const item = this.findTarget(this.itemTargets, key)

    if (item.classList.contains('list__item--open')) {
      this.hideItem(item)
    } else {
      this.showItem(item, key)
    }
  }

  findTarget = (targets, key) => targets.find((target) => target.dataset.key == key)

  getIcon = (item) => item.querySelector('[data-accordion-target=icon] > svg')

  getDescription = (item) => item.querySelector('[data-accordion-target=description]')

  showItem(item, key) {
    const icon = this.getIcon(item)
    const description = this.getDescription(item)

    item.classList.add('list__item--open')
    icon.classList.add('term__icon--open')
    description.classList.remove('hide')

    this.hideItems(key)
  }

  hideItem(item) {
    const icon = this.getIcon(item)
    const description = this.getDescription(item)

    if (item.classList.contains('list__item--open'))
      item.classList.remove('list__item--open')
    if (icon.classList.contains('term__icon--open'))
      icon.classList.remove('term__icon--open')
    if (!description.classList.contains('hide')) description.classList.add('hide')
  }

  hideItems(key) {
    this.itemTargets
      .filter((item) => item.dataset.key != key)
      .forEach((item) => this.hideItem(item))
  }
}
