import { Controller } from 'stimulus'

export default class extends Controller {
  format = (event) => {
    const input = event.target.value
    const formattedInput = this.formatTime(input)

    event.target.value = formattedInput
  }

  formatTime = (input) => {
    // Remove all non-digit except for : characters
    let parseInput = input.replace(/[^:\d]/g, '')
    const inputLength = parseInput.length
    const haveColon = /:/.test(parseInput)

    if (inputLength > 8 && haveColon) {
      parseInput = parseInput.slice(0, -1)
    }

    return parseInput
  }
}
