import { Controller } from 'stimulus'

export default class extends Controller {

  toggleAnswer(event) {
    event.currentTarget.classList.toggle('selected')

    let input = event.currentTarget.querySelector("input[name*='selected']")
    input.value = (input.value == 'false') ? 'true' : 'false'
  }
}
