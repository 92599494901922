import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['toggle', 'label']
  static values = {
    showLabelText: String,
    hideLabelText: String,
  }

  call(event) {
    if (!this.hasToggleTarget) return

    this.toggleTarget.classList.toggle('hide')

    if (this.toggleTarget.classList.contains('hide')) {
      if (this.hasLabelTarget) this.labelTarget.innerHTML = this.showLabelTextValue
    } else {
      this.toggleTarget.scrollIntoView({ behavior: 'smooth' })

      if (this.hasLabelTarget) this.labelTarget.innerHTML = this.hideLabelTextValue
    }
  }
}
