import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["input"]
  set(event) {
    event.preventDefault();
    console.log(event.target.value)
    this.inputTarget.value = event.target.value;
  }
}
