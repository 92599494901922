import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "source", "target" ];

    handleSelectChange() {
        this.populateSelect(this.sourceTarget.value);
    }

    populateSelect(sourceId, targetId = null) {
        // TODO: Set the get request URL dynamically
        var fullRoute = this.data.get('targetRoute');
        if (fullRoute !== null) {
            var fetchUrl = `${fullRoute}${sourceId}`;
        } else {
            var filter = `?filter=${this.data.get('targetParams')}`;
            var fetchUrl = `/${this.data.get('sourceRoutePart')}/${sourceId}/${this.data.get('targetRoutePart')}/${filter}`
        }

        fetch(fetchUrl, {
            credentials: 'same-origin'
        })
        .then(response => response.json())
        .then(data => {
            const selectBox = this.targetTarget;
            selectBox.innerHTML = '';

            // Include blank option
            const blank_opt = document.createElement('option');
            blank_opt.innerHTML = ''
            selectBox.appendChild(blank_opt);

            data.forEach(item => {
                const opt = document.createElement('option');
                opt.value = item.id || item[1];
                opt.innerHTML = item[this.data.get('displayAttribute')] || item[0];
                opt.selected = parseInt(targetId) === item.id;
                selectBox.appendChild(opt);
            });
        });
    }
}
