import { Controller } from 'stimulus'
import { addSelectionId, removeSelectionId } from '../src/utils'

export default class extends Controller {
  static targets = ['input', 'content']

  // TODO: this should be refactored to use the updateInput function
  duplicateCourses(event) {
    //  Populate course_ids to duplicate
    const selected_ids = []
    document.querySelectorAll('.bulk-checkbox').forEach((box) => {
      if (box.checked) {
        selected_ids.push(box.dataset.courseId)
      }
    })

    //  Conditionally warn of unwanted actions
    if (selected_ids.length > 0) {
      document.querySelector('#duplicate_courses_input').value = selected_ids
      this.contentTarget.innerText = `You are about to duplicate ${selected_ids.length} courses. Do you want to continue?`
      // event.preventDefault()
    } else {
      this.contentTarget.innerText = 'Please select courses to duplicate'
      event.preventDefault()
    }
  }

  updateInput({ detail: { action, selectedIds } }) {
    if (!this.hasInputTarget || !Array.isArray(selectedIds)) return

    selectedIds = selectedIds.filter(Boolean).flat()
    if (action == 'add') {
      selectedIds.forEach((id) => addSelectionId(id, this.inputTarget))
    } else if (action == 'remove') {
      selectedIds.forEach((id) => removeSelectionId(id, this.inputTarget))
    }
  }
}
