import { Controller } from 'stimulus'

export default class extends Controller {

  toggle () {
    document.querySelector('.course-navigation').classList.toggle('hide')
  }

  close () {
    alert('asdf')
  }
}