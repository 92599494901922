import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['table']

  connect() {
    if (!this.hasTableTarget) return

    const selectedIds = this.getSelectedIds(document.querySelectorAll('.bulk-checkbox'))
    this.dispatchSelection('add', selectedIds)
    this.button = document.querySelector(`#${this.tableTarget.dataset.buttonId}`)
  }

  toggleAllRows(event) {
    const checkBoxes = document.querySelectorAll('.bulk-checkbox')

    if (event.target.checked) {
      checkBoxes.forEach((box) => {
        box.checked = true
      })

      if (this.hasButtonAction()) {
        this.dispatchSelection('add', this.getSelectedIds(checkBoxes))
        this.button.disabled = false
      }
    } else {
      checkBoxes.forEach((box) => {
        box.checked = false
      })

      if (this.hasButtonAction()) {
        const allIds = Array.from(checkBoxes, (element) => {
          return element.value
        })
        this.dispatchSelection('remove', allIds)
        this.button.disabled = true
      }
    }
  }

  toggleButton(event) {
    if (!this.hasButtonAction()) return

    if (this.tableTarget.querySelectorAll('.bulk-checkbox:checked').length > 0) {
      this.button.disabled = false
    } else {
      this.button.disabled = true
    }

    const target = event.currentTarget
    if (target.checked) {
      this.dispatchSelection('add', target.value)
    } else {
      this.dispatchSelection('remove', target.value)
    }
  }

  toggleDetails(event) {
    if (event.target.type === 'button') return

    const enrolmentActionId = event.target.closest('tr').dataset.enrolmentActionId
    const enrolmentActionDetailsElements = document.getElementsByClassName(
      `enrolment-action-${enrolmentActionId}-details`
    )

    for (let enrolmentActionDetailsElement of enrolmentActionDetailsElements) {
      enrolmentActionDetailsElement.classList.toggle('hide')
    }
  }

  getSelectedIds(checkBoxes) {
    return Array.from(checkBoxes, (element) => {
      if (element.checked) return element.value
    }).filter(Boolean)
  }

  dispatchSelection(action, ids) {
    if (Array.isArray(ids)) ids = ids.filter(Boolean)
    if (!ids || ids.length == 0) return

    const SelectionChange = new CustomEvent('SelectionChange', {
      detail: { action, selectedIds: [ids].flat() },
    })

    window.dispatchEvent(SelectionChange)
  }

  hasButtonAction = () => this.hasTableTarget && !!this.button
}
