import { Controller } from 'stimulus'
import { getPresignedSignature, getXHRRequest } from 'src/aws'
import { isValidFileType, clearHover, lessThanMaxSizeLimit } from 'src/utils'

export default class extends Controller {
  static targets = ['container']

  //  Updates and existing caption file
  update(event) {
    const params = {
      video_id: this.containerTarget.dataset.videoId,
      caption_id: event.target.dataset.captionId,
      language: event.target.value
    }

    const token = document.querySelector('input[name=authenticity_token]').value

    fetch(`/lms/videos/${params.video_id}/captions/${params.caption_id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        "X-CSRF-Token": token
      },
      body: JSON.stringify(params)
     }).catch(error => console.log(error))
  }

  //  Removes the caption from the form and database
  delete(event) {
    event.preventDefault()
    const captionId = event.currentTarget.dataset.captionId || event.target.dataset.captionId
    const videoId = this.containerTarget.dataset.videoId

    if (videoId && captionId && window.confirm('Are you sure you want to delete this caption?')) {
      const token = document.querySelector('input[name=authenticity_token]').value
      fetch(`/lms/videos/${videoId}/captions/${captionId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "X-CSRF-Token": token
        }
      }).then(() => {
        const captionBar = document.querySelector(`#caption-${captionId}`)
        captionBar.remove()
      }).catch(error => alert('There looks to have been an error deleting this caption'))
    }
  }
}
