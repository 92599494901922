import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "modeInput" ]
  static values = {
    originalMode: String,
    modes: Array
  }

  connect() {
    this.currentMode = this.originalModeValue;
    this.indexMode = this.modesValue.indexOf(this.currentMode);
  }

  toggleMode() {
    this.indexMode = (this.indexMode + 1) % this.modesValue.length;
    this.currentMode = this.modesValue[this.indexMode];
    this.modeInputTarget.value = this.currentMode;
  }
}