import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form']

  toggleSelection(event) {
    let videos = document.getElementsByClassName('video-row')

    for(let video of videos) {
      video.querySelector('#video_checkbox').checked = event.target.checked
    }
  }

  submit(event) {
    event.preventDefault()

    let dataset = event.target.closest('a').dataset

    if (dataset.hidden !== undefined)
      this.addHiddenField('hidden', dataset.hidden)
    if (dataset.featured !== undefined)
      this.addHiddenField('featured', dataset.featured)

    this.formTarget.submit()
  }

  addHiddenField(name, value) {
    let hiddenInput = document.createElement("input")

    hiddenInput.setAttribute("type", "hidden")
    hiddenInput.setAttribute("name", name)
    hiddenInput.setAttribute("value", value)

    this.formTarget.appendChild(hiddenInput)
  }
}
