import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['correct', 'delete']

  select(event) {
    this.correctTarget.classList.toggle('not')
  }

  delete(event) {
    event.preventDefault()
    let id = this.deleteTarget.dataset.answerId
    if (id) {
      document.querySelector('#answer-'+id).remove()

      let token = document.querySelector('input[name=authenticity_token]').value
      fetch(`/lms/answers/${id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            "X-CSRF-Token": token
        }
      })
    }
  }
}