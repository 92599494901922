import StyleHelpers from './style_helpers';

// modal controller took it from https://github.com/excid3/tailwindcss-stimulus-components/blob/master/src/modal.js
// and modified to work without tailwind
export default class extends StyleHelpers {
  static targets = ['container'];

  connect() {
    // The ID of the background to hide/remove
    this.backgroundId = this.data.get('backgroundId') || 'modal-background';

    // The HTML for the background element
    this.backgroundHtml = this._backgroundHTML();

    // Let the user close the modal by clicking on the background
    this.allowBackgroundClose =
      (this.data.get('allowBackgroundClose') || 'true') === 'true';

    // Prevent the default action of the clicked element (following a link for example) when opening the modal
    this.preventDefaultActionOpening =
      (this.data.get('preventDefaultActionOpening') || 'true') === 'true';

    // Prevent the default action of the clicked element (following a link for example) when closing the modal
    this.preventDefaultActionClosing =
      (this.data.get('preventDefaultActionClosing') || 'true') === 'true';

    // Stop propagation of the clicked element when opening the modal
    this.stopPropagationActionOpening =
      (this.data.get('preventDefaultActionClosing') || 'false') === 'false';
  }

  disconnect() {
    this.close();
  }

  open(e) {
    if (this.stopPropagationActionOpening) e.stopPropagation();

    if (this.preventDefaultActionOpening) {
      e.preventDefault();
    }

    if (e.target.blur) {
      e.target.blur();
    }

    // Lock the scroll and save current scroll position
    this.lockScroll();

    // Unhide the modal
    this.styleElement(this.containerTarget, {display: 'flex'})

    // Insert the background
    if (!this.data.get('disable-backdrop')) {
      this.data.element.insertAdjacentHTML('beforeend', this.backgroundHtml);
      this.background = document.querySelector(`#${this.backgroundId}`);
    }
  }

  close(e) {
    if (e && this.preventDefaultActionClosing) {
      e.preventDefault();
    }

    // Unlock the scroll and restore previous scroll position
    this.unlockScroll();

    // Hide the modal
    this.styleElement(this.containerTarget, {display: 'none'})


    // Remove the background
    if (this.background) {
      this.background.remove();
    }
  }

  closeBackground(e) {
    if (this.allowBackgroundClose && e.target === this.containerTarget) {
      this.close(e);
    }
  }

  closeWithKeyboard(e) {
    if (e.keyCode === 27 && this.containerTarget.style.display != 'none') {
      this.close(e);
    }
  }

  _backgroundHTML() {
    return `<div id="${this.backgroundId}"
                 style="background-color: rgba(0, 0, 0, 0.7); 
                        z-index: 9998;
                        position: fixed;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;">
              </div>`;
  }

  lockScroll() {
    // Add right padding to the body so the page doesn't shift
    // when we disable scrolling
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.paddingRight = `${scrollbarWidth}px`;

    // Save the scroll position
    this.saveScrollPosition();

    // Add classes to body to fix its position
    this.styleElement(document.body, {
      position: 'fixed',
      left: '0px',
      right: '0px',
      overflow: 'hidden',
    });

    // Add negative top position in order for body to stay in place
    document.body.style.top = `-${this.scrollPosition}px`;
  }

  unlockScroll() {
    // Remove tweaks for scrollbar
    document.body.style.paddingRight = null;

    // Remove classes from body to unfix position
    this.styleElement(document.body, {
      position: '',
      left: '',
      right: '',
      overflow: '',
    });

    // Restore the scroll position of the body before it got locked
    this.restoreScrollPosition();

    // Remove the negative top inline style from body
    document.body.style.top = null;
  }

  saveScrollPosition() {
    this.scrollPosition = window.pageYOffset || document.body.scrollTop;
  }

  restoreScrollPosition() {
    if (this.scrollPosition === undefined) return;

    document.documentElement.scrollTop = this.scrollPosition;
  }
}
